import React from 'react';
import { MotionValue, useTransform } from 'framer-motion';

import { useMatchMedia } from '../../hooks/use-match-media';
import { ScContainer } from '../container/styled';
import { ScParagraph } from '../Paragraph/styled';

import {
	ScCardContent,
	ScCardTextContent,
	ScParallaxCardContainer,
	ScParallaxCardImage,
	ScParallaxCardWrapper,
} from './styled';

interface ParallaxCardProps {
	i: number;
	title: string;
	text: string;
	image: string;
	color: string;
	progress: MotionValue<number>;
	range: [number, number];
	targetScale: number;
}

export const ParallaxCard = ({
	i,
	title,
	text,
	image,
	color,
	progress,
	range,
	targetScale,
}: ParallaxCardProps) => {
	const scale = useTransform(progress, range, [1, targetScale]);
	const isDesktop = useMatchMedia(`(min-width: 1024px)`);

	return (
		<ScParallaxCardContainer className={'cardContainer'}>
			<ScParallaxCardWrapper
				style={{
					scale,
					top: `calc(${isDesktop ? 110 : 60}px + ${i * (isDesktop ? 40 : 20)}px)`,
				}}
				className={`card${i} card`}
			>
				<ScContainer>
					<ScCardContent background={color} id={title}>
						<ScParallaxCardImage image={image} />
						<ScCardTextContent>
							<h6>{title}</h6>
							<ScParagraph centered={!isDesktop}>{text}</ScParagraph>
						</ScCardTextContent>
					</ScCardContent>
				</ScContainer>
			</ScParallaxCardWrapper>
		</ScParallaxCardContainer>
	);
};
