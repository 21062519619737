import styled from 'styled-components';

export const ScWideSliderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: 1.5fr 2fr;
		gap: 20px;
	}
	@media (min-width: 1440px) {
		display: grid;
		grid-template-columns: 2fr 3fr;
		gap: 16px;
	}
	& .slick-track {
		display: flex;
		flex-direction: row;
	}
	.active-dot {
		background: ${({ theme }) => theme.colors.darkText};
		@media (min-width: 1220px) {
			background: ${({ theme }) => theme.colors.strongWhite};
		}
	}
`;

interface IScWideSliderTextWrapper {
	color?: string;
	justifyContent?: string;
	paddingTop?: string;
}

export const ScWideSliderTextWrapper = styled.div<IScWideSliderTextWrapper>`
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	position: relative;
	min-height: 190px;
	border-radius: 10px;
	padding: 40px 40px 25px 40px;
	box-sizing: border-box;
	background: ${({ theme, color }) => color || theme.colors.peach};
	button {
		margin-bottom: 30px;
	}
	@media (min-width: 768px) {
		min-height: 220px;
	}
	@media (min-width: 1220px) {
		justify-content: center;
		gap: 50px;
		border-radius: 30px;
		padding: 40px 55px 52px 55px;
		min-height: 624px;
		height: 624px;
	}
	@media (min-width: 1440px) {
		justify-content: ${({ justifyContent }) => justifyContent || 'start'};
		gap: 50px;
		border-radius: 30px;
		padding: ${({ paddingTop }) => `${paddingTop} 55px 52px 55px`};
		min-height: 624px;
		height: 624px;
	}
	@media (min-width: 1920px) {
		min-height: 690px;
		height: 690px;
	}
`;

interface IScWideSliderSlideWrapper {
	isLarge?: boolean;
}

export const ScWideSliderSlideWrapper = styled.div<IScWideSliderSlideWrapper>`
	display: flex !important;
	justify-content: center;
	padding: 40px 55px;
	box-sizing: border-box;
	height: 100%;
	min-height: 360px;
	img {
		width: ${({ isLarge }) => (isLarge ? '240px' : '105px')};

		height: auto;
		margin-top: auto;
	}
	@media (min-width: 1220px) {
		padding: ${({ isLarge }) => (isLarge ? '12px 90px 45px 90px' : '90px')};
		min-height: 690px;
		height: 690px;
		img {
			width: ${({ isLarge }) => (isLarge ? '630px' : '235px')};
		}
	}
	@media (min-width: 1220px) {
		min-height: 624px;
		height: 624px;
	}
	@media (min-width: 1440px) {
		img {
			max-height: 100%;
		}
	}
	@media (min-width: 1920px) {
		min-height: 690px;
		height: 690px;
	}
`;

export const ScWideSliderWrapper = styled.div`
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.wheat};
	@media (min-width: 1220px) {
		border-radius: 30px;
		max-width: calc((100vw - 250px) / 1.5);
	}
	@media (min-width: 1440px) {
		border-radius: 30px;
		max-width: 789px;
	}
	@media (min-width: 1500px) {
		max-width: calc((100vw - 250px) / 1.5);
	}
	@media (min-width: 1920px) {
		max-width: 1200px;
	}
`;

export const ScWideSliderSlideTitle = styled.h6`
	color: ${({ theme, color }) => color || theme.colors.darkText};
	text-align: center;
	font: ${({ theme }) => theme.fonts.heading.xs};
	margin-bottom: 30px;
	@media (min-width: 768px) {
		margin: 0;
		font: ${({ theme }) => theme.fonts.slider.title};
	}
	@media (min-width: 1440px) {
		margin: 0;
		font: ${({ theme }) => theme.fonts.slider.titleMedium};
	}
`;

export const ScWideSliderSlideDots = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	position: absolute;
	bottom: 20px;
	@media (min-width: 1220px) {
		bottom: 40px;
	}
	@media (min-width: 1920) {
		gap: 20px;
	}
`;

export const ScWideSliderSlideDot = styled.div`
	width: 18px;
	height: 5px;
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.strongWhite};
	cursor: pointer;
	@media (min-width: 1220px) {
		background: ${({ theme }) => theme.colors.darkText};
		width: 24px;
		height: 12px;
	}
`;
