import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInRight, fadeInUp } from '../../animations/variants';
import Image from '../../assets/images/bread-home.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScLargeTitle } from '../../components/Title/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScHomeHeroButtons, ScHomeHeroWrapper } from './styled';

export const Hero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScTop background={theme.colors.blueberry}>
				<ScContainer>
					<ScHomeHeroWrapper>
						<MotionDiv variants={fadeInUp}>
							<ScLargeTitle className="hero-title" centered color={theme.colors.wheat}>
								same dough
								<br />
								more bread
							</ScLargeTitle>
						</MotionDiv>
						<MotionImage variants={fadeInRight} src={Image} alt="bread" />
						<MotionDiv variants={fadeInUp} className="buttons">
							<ScHomeHeroButtons>
								<Link to="/employee-product/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										I’m an employee
									</CustomButton>
								</Link>
								<Link to="/employer-product/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										I’m an employer
									</CustomButton>
								</Link>
								<Link to="/provider-pathway/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										I'm a provider
									</CustomButton>
								</Link>
							</ScHomeHeroButtons>
						</MotionDiv>
					</ScHomeHeroWrapper>
				</ScContainer>
			</ScTop>
		</motion.section>
	);
};
