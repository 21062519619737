import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import image from '../../assets/images/how-does-that-work.png';
import image1 from '../../assets/images/wide-home-1.png';
import image2 from '../../assets/images/wide-home-2.png';
import image3 from '../../assets/images/wide-home-3.png';
import image4 from '../../assets/images/wide-home-4.png';
import image5 from '../../assets/images/wide-home-5.png';
import image6 from '../../assets/images/wide-home-6.png';
import image7 from '../../assets/images/wide-home-7.png';
import image8 from '../../assets/images/wide-home-8.png';
import image9 from '../../assets/images/wide-home-9.png';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import WideSlider, { ISlide } from '../../components/WideSlider';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { defaultTheme } from '../../styles/themes';

import {
	ScHowDoesThatWorkContainer,
	ScHowDoesThatWorkContent,
	ScHowDoesThatWorkTextWrapper,
	ScHowDoesThatWorkWrapper,
} from './styled';

const slides: ISlide[] = [
	{ title: 'Here’s the low down', image: image1 },
	{ title: 'You earn your gross salary', image: image2 },
	{ title: 'And you pay tax on the full amount', image: image3 },
	{ title: 'What you’re left with is your take home pay', image: image4 },
	{
		title: 'Out of that, you pay for things like a new bike or your nursery bills',
		image: image5,
	},
	{ title: 'Which means less left at the end of the month', image: image6 },
	{
		title: 'With Gogeta you pay for essentials out of your gross pay',
		image: image7,
		color: defaultTheme.colors.blueberry,
		isLarge: true,
	},
	{
		title: 'Which reduces the amount of tax you pay',
		image: image8,
		color: defaultTheme.colors.blueberry,
		isLarge: true,
	},

	{
		title: 'That means a bigger slice of your take home pay is yours to keep',
		image: image9,
		color: defaultTheme.colors.blueberry,
		isLarge: true,
	},
];

export const HowDoesThatWork = () => {
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();
	return (
		<ScHowDoesThatWorkContainer>
			<Top />
			<ScHowDoesThatWorkWrapper>
				<ScContainer>
					<motion.section initial="hidden" animate={controls} variants={animationContainer}>
						<ScHowDoesThatWorkContent>
							<MotionDiv variants={fadeInRight}>
								<ScHowDoesThatWorkTextWrapper>
									<ScTitle centered={!isDesktop}>Wait, how does that work?</ScTitle>
									<ScParagraph centered={!isDesktop}>
										<p>
											You might be wondering what all the bread is about. Good point. Let’s get into
											it.
										</p>
										<p>
											Basically, we found that using a simple loaf of bread is a good way to explain
											what we do. All you need to know is our friend Loaf here represents your
											monthly salary, or your dough, if you will.
										</p>
										<p ref={ref}>
											Gogeta helps you use your loaf a bit differently, allowing you to pay for
											things before you’re taxed. So you get to keep an extra slice of your salary,
											without having to increase your dough.
										</p>
									</ScParagraph>
								</ScHowDoesThatWorkTextWrapper>
							</MotionDiv>
							<MotionImage variants={fadeInLeft} src={image} alt="how that works image" />
						</ScHowDoesThatWorkContent>
					</motion.section>
					<WideSlider slides={slides} />
				</ScContainer>
			</ScHowDoesThatWorkWrapper>
			<Bot />
		</ScHowDoesThatWorkContainer>
	);
};
