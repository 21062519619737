import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScParallaxMain = styled.main`
	position: relative;
	margin-top: 50px;
	.card1 {
		margin-top: -100vh;
	}
	.card2,
	.card3 {
		margin-top: 100vh;
	}
	@media (min-width: 1024px) {
		margin-bottom: 100px;
	}
`;

export const ScParallaxNavigationContainer = styled.div`
	height: 100vh;
	top: 5px;
	position: sticky;
	z-index: 1000;
	> div {
		height: 100px;
		@media (min-width: 1024px) {
			height: 150px;
		}
	}
`;

export const ScParallaxNavigationWrapper = styled.div`
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px;
	width: 100%;
	@media (min-width: 768px) {
		width: fit-content;
		gap: 24px;
	}
	button {
		width: 100%;
		min-height: 42px;
	}
`;

export const ScParallaxCardWrapper = styled(motion.div)`
	position: relative;
	transform-origin: top;
	height: 520px;
	@media (min-width: 768px) {
		height: calc(100vh - 200px);
	}
	@media (min-width: 1024px) and (min-height: 600px) {
		height: calc(100vh - 250px);
	}
	@media (max-width: 1024px) and (max-height: 700px) and (min-width: 500px) {
		height: calc(100vh - 130px);
	}
`;

interface IScCardContent {
	background: string;
}

export const ScCardContent = styled.div<IScCardContent>`
	box-sizing: border-box;
	border-radius: 10px;
	background: ${({ theme, background }) => background || theme.colors.toasted};
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: center;
	padding: 35px 45px 50px 45px;
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: 5fr 8fr;
		gap: 50px;
		border-radius: 30px;
		padding: 50px 45px;
	}
	@media (min-width: 1440px) {
		@media (min-height: 700px) {
			gap: 200px;
			padding: 50px 100px;
		}
	}
	@media (min-width: 1920px) {
		gap: 200px;
	}
	@media (max-width: 1024px) and (max-height: 700px) and (min-width: 500px) {
		padding: 25px 30px;
		align-items: center;
		justify-content: center;
	}
`;

export const ScParallaxCardContainer = styled.div`
	height: 100vh;
	position: sticky;
	top: 5px;
`;

export const ScCardTextContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	@media (min-width: 1024px) {
		@media (min-height: 700px) {
			gap: 60px;
		}
	}
	h6 {
		font: ${({ theme }) => theme.fonts.heading.small};
		text-align: center;
	}
	@media (min-width: 1024px) {
		@media (min-height: 700px) {
			h6 {
				font: ${({ theme }) => theme.fonts.heading.xl};
				text-align: left;
			}
		}
	}
	@media (max-width: 1024px) and (max-height: 700px) and (min-width: 500px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.small};
			text-align: center;
		}
	}
`;

interface IScParallaxCardImage {
	image: string;
}

export const ScParallaxCardImage = styled.div<IScParallaxCardImage>`
	width: 180px;
	height: 185px;
	background-image: ${({ image }) => `url(${image})`};
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	@media (min-width: 768px) {
		width: 60%;
		height: 100%;
	}
	@media (min-width: 1024px) {
		width: 100%;
	}
	@media (max-width: 1024px) and (max-height: 700px) and (min-width: 500px) {
		display: none;
	}
`;
