import { useRef } from 'react';
import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { ScContainer } from '../container/styled';
import { ScButton } from '../custom-button/styled';

import { ScParallaxNavigationContainer, ScParallaxNavigationWrapper } from './styled';
import { ICard } from '.';

export const ParallaxNavigation = ({
	cards,
	scrollToCard,
	currentSectionId,
}: {
	cards: ICard[];
	scrollToCard: (index: number) => void;
	currentSectionId: number;
}) => {
	const container = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	return (
		<ScParallaxNavigationContainer ref={container} className={'navigationContainer'}>
			<motion.div
				style={{
					top: `5px`,
				}}
			>
				<ScContainer>
					<ScParallaxNavigationWrapper>
						{cards.map((item, index) => (
							<ScButton
								background={index === currentSectionId ? item.color : theme.colors.darkText}
								hoverBackground={item.color}
								color={
									index === currentSectionId ? theme.colors.darkText : theme.colors.strongWhite
								}
								key={index}
								onClick={() => scrollToCard(index)}
							>
								{item.title}
							</ScButton>
						))}
					</ScParallaxNavigationWrapper>
				</ScContainer>
			</motion.div>
		</ScParallaxNavigationContainer>
	);
};
