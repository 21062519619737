import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useScroll } from 'framer-motion';
import { useTheme } from 'styled-components';

import Lenis from '@studio-freight/lenis';
import { isNotSSR } from '@utils';

import bike from '../../assets/images/bike-logo.png';
import nursery from '../../assets/images/nursery-logo.png';
import tech from '../../assets/images/tech.png';

import { ParallaxCard } from './ParallaxCard';
import { ParallaxNavigation } from './ParallaxNavigation';
import { ScParallaxMain } from './styled';

export interface ICard {
	title: string;
	text: string;
	image: string;
	color: string;
}

export const ParallaxCards = () => {
	useEffect(() => {
		const lenis = new Lenis();
		function raf(time: number) {
			lenis.raf(time);

			requestAnimationFrame(raf);
		}
		requestAnimationFrame(raf);
	});
	const theme = useTheme();
	const cards: ICard[] = [
		{
			title: 'Bike',
			text: 'That new road bike you’ve been eyeing up? A cargo bike for the school run? Go get it – and a bit more – with Gogeta. Our cycle to work voucher is fully flexible, so you can use it all in one go , or save some for extra bits you need later in the year. We make the savings, how you spend them is up to you.',
			image: bike,
			color: theme.colors.marmelade,
		},
		{
			title: 'Nursery',
			text: 'Finally, a little bit of good news about childcare costs. With Gogeta, nursery fees can be reduced by thousands of pounds simply by paying for them out of your gross salary, before you pay tax. More good news: Gogeta benefits your nursery, too.',
			image: nursery,
			color: theme.colors.blueberry,
		},
		{
			title: 'Tech',
			text: "Fancy a new laptop? Washing machine on the blink? Our tech scheme lets you save up to 8% and pay interest free over 12 months. Now that's tech-tastic. Coming soon.",
			image: tech,
			color: theme.colors.peach,
		},
	];

	const container = useRef<HTMLDivElement>(null);
	const [currentSectionId, setCurrentSectionId] = useState(0);
	const { scrollYProgress } = useScroll({
		target: container,
		offset: ['start start', 'end end'],
	});

	const scrollToCard = (index: number) => {
		if (container.current && isNotSSR) {
			const containerRect = container.current.getBoundingClientRect();
			const documentTop = window.pageYOffset || document.documentElement.scrollTop;

			const distanceFromTop = containerRect.top + documentTop;

			window.scroll({
				top: distanceFromTop + 2 * window.innerHeight * index,
				behavior: 'smooth',
			});
		}
	};
	useEffect(() => {
		const handleScroll = () => {
			if (container.current) {
				const containerRect = container.current.getBoundingClientRect();
				const documentTop = window.pageYOffset || document.documentElement.scrollTop;

				const distanceFromTop = Math.round(containerRect.top + documentTop);
				if (window.scrollY < distanceFromTop + 2 * window.innerHeight) {
					setCurrentSectionId(0);
				} else if (window.scrollY < distanceFromTop + 4 * window.innerHeight) {
					setCurrentSectionId(1);
				} else {
					setCurrentSectionId(2);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<ScParallaxMain ref={container} className={'main'}>
			<ParallaxNavigation
				cards={cards}
				scrollToCard={scrollToCard}
				currentSectionId={currentSectionId}
			/>
			{cards.map((project, i) => {
				const targetScale = 1.2 - (cards.length - i + 1) * 0.1;
				return (
					<ParallaxCard
						key={`p_${i + i}`}
						i={i + 1}
						{...project}
						progress={scrollYProgress}
						range={[(i + 1) * 0.25, 1]}
						targetScale={targetScale}
					/>
				);
			})}
		</ScParallaxMain>
	);
};
