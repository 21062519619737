import { useRef, useState } from 'react';
import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import useContentAnimation from '../../hooks/use-content-animation';
import { CustomButton } from '../custom-button';
import { ScTitle } from '../Title/styled';

import {
	ScWideSliderContainer,
	ScWideSliderSlideDot,
	ScWideSliderSlideDots,
	ScWideSliderSlideTitle,
	ScWideSliderSlideWrapper,
	ScWideSliderTextWrapper,
	ScWideSliderWrapper,
} from './styled';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface ISlide {
	title: string;
	image: string;
	color?: string;
	isLarge?: boolean;
}

export default function WideSlider({ slides }: { slides: ISlide[] }) {
	const [activeSlide, setActiveSlide] = useState(0);
	const sliderRef = useRef<Slider | null>(null);
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		beforeChange: (_current: number, next: number) => setActiveSlide(next),
	};
	return (
		<motion.div initial="hidden" animate={controls} variants={animationContainer}>
			<MotionDiv variants={fadeInUp}>
				<ScWideSliderContainer>
					<ScWideSliderTextWrapper
						color={slides[activeSlide]?.color}
						justifyContent={activeSlide === 0 ? 'center' : 'start'}
						paddingTop={activeSlide === 0 ? '40px' : '270px'}
					>
						{activeSlide === 0 ? (
							<>
								<ScTitle centered>{slides[activeSlide].title}</ScTitle>
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.strongWhite}
									onClick={() => sliderRef.current?.slickNext()}
								>
									Talk me through it
								</CustomButton>
							</>
						) : (
							<ScWideSliderSlideTitle>{slides[activeSlide].title}</ScWideSliderSlideTitle>
						)}
						<ScWideSliderSlideDots>
							{slides.map((item, index) => (
								<ScWideSliderSlideDot
									key={item.title}
									className={index === activeSlide ? 'active-dot' : 'dot'}
									onClick={() => {
										sliderRef.current?.slickGoTo(index);
										setActiveSlide(index);
									}}
								/>
							))}
						</ScWideSliderSlideDots>
					</ScWideSliderTextWrapper>
					<ScWideSliderWrapper>
						<Slider {...settings} ref={sliderRef}>
							{slides.map((item) => (
								<ScWideSliderSlideWrapper isLarge={item?.isLarge} key={item.title}>
									<img src={item.image} alt="slide" />
								</ScWideSliderSlideWrapper>
							))}
						</Slider>
					</ScWideSliderWrapper>
				</ScWideSliderContainer>
				<div ref={ref} />
			</MotionDiv>
		</motion.div>
	);
}
